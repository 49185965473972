//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OtpTimer from './OtpTimer.vue';

export default {
    name: 'OTPInput',
    components: { OtpTimer },
    props: {
        error: {
            type: String,
            value: '',
            required: true
        },
        value: {
            type: String,
            value: '',
            required: true
        },
        clearOtpInputs: {
            type: Boolean
        },
        timerDuration: {
            type: Number,
            default: 45
        }
    },
    data() {
        return {
            otp: this.value,
            timerRunning: false,
            timerSeconds: 0,
            info: '',
            backspaceCtr: 0,
            firstInput: '',
            abortController: new AbortController()
        };
    },
    watch: {
        clearOtpInputs(newVal) {
            if (newVal) {
                this.$refs[0].value = '';
                this.$refs[1].value = '';
                this.$refs[2].value = '';
                this.$refs[3].value = '';
                this.$refs[4].value = '';
                this.$refs[5].value = '';
                this.focusInput(0);
            }
        }
    },
    mounted() {
        this.focusInput(0);
        this.timerSeconds = this.timerDuration;
        this.timerRunning = true;
        this.autoFillOTP();
    },

    destroyed() {
        this.abortController.abort();
    },

    methods: {
        async autoFillOTP() {
            if ('OTPCredential' in window) {
                // navigator.credentials.get attachs an EventListner
                // It won't terminate unless OTP is received
                // Incase if OTP is not received, need to Terminate EventListner
                // Terminating after 60 Seconds

                setTimeout(() => {
                    this.abortController.abort();
                }, 60000);

                try {
                    // eslint-disable-next-line no-unused-vars
                    const { code, type } = await navigator.credentials.get({
                        otp: { transport: ['sms'] },
                        signal: this.abortController.signal
                    });

                    this.handlePaste1(code);
                } catch (error) {}
            }
        },
        isNumber(event) {
            if (!(event.ctrlKey || event.metaKey) && !(event.keyCode > 47 && event.keyCode < 58)) {
                event.preventDefault();
            }
            if (event.key === 'ArrowLeft') {
                event.preventDefault();
                this.focusInput(5);
            }
            if (event.key === 'ArrowRight') {
                this.focusInput(1);
            }
        },
        firstChange(event) {
            this.info = '';
            if (this.error !== '') {
                this.$emit('resetError');
            }
            const target = event.target.value;
            if (target.length === 1 && target.match(/^\d+$/)) {
                this.$refs[0].value = target[0];
                this.focusInput(1);
                return;
            }
            if (!target.match(/^\d+$/)) {
                this.$refs[0].value = '';
                this.focusInput(0);
                return;
            }
            if (target.length > 1 && target.length === 6) {
                this.handlePaste1(target);
            }
            if (target.length > 6 || target.length < 6) {
                this.$refs[0].value = '';
            }
        },
        focusInput(inp) {
            this.$nextTick(() => {
                this.$refs[inp].focus();
            });
        },
        sendOtp() {
            this.$refs[0].value = '';
            this.$refs[1].value = '';
            this.$refs[2].value = '';
            this.$refs[3].value = '';
            this.$refs[4].value = '';
            this.$refs[5].value = '';
            this.focusInput(0);
            if (!this.timerRunning) {
                this.timerRunning = true;
                this.timerSeconds = this.timerDuration;
                this.info = 'Your Code has been resent';
                this.$emit('resend-otp');
            }
        },
        timerCallback() {
            this.timerRunning = false;
            this.info = '';
        },

        next(i, event) {
            if (this.error !== '') {
                this.$emit('resetError');
            }
            const target = event.target;
            if (event.key === 'Backspace') {
                event.preventDefault();
                this.otp = this.otp.slice(0, i);
                if (i !== 0 && target.value === '') {
                    this.focusInput(i - 1);
                    this.$refs[i - 1].value = '';
                }
                target.value = '';
            } else if (event.key !== 'Enter') {
                if (event.keyCode > 47 && event.keyCode < 58 && event.key.match(/^\d+$/)) {
                    target.value = event.key;
                    if (i !== 5) {
                        this.$refs[i + 1].focus();
                    }
                }
                if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                    if (i === 0) {
                        this.$refs[5].focus();
                    } else {
                        this.$refs[i - 1].focus();
                    }
                } else if (event.key === 'ArrowRight') {
                    event.preventDefault();
                    if (i === 5) {
                        this.$refs[0].focus();
                    } else {
                        this.$refs[i + 1].focus();
                    }
                }
                event.preventDefault();
            }
            this.setOTP();
        },
        handlePaste1(copiedValue) {
            this.otp = copiedValue;
            this.$refs[0].value = this.otp[0];
            this.$refs[1].value = this.otp[1];
            this.$refs[2].value = this.otp[2];
            this.$refs[3].value = this.otp[3];
            this.$refs[4].value = this.otp[4];
            this.$refs[5].value = this.otp[5];
            this.$refs[5].focus();

            this.$emit('input', this.otp);
            if (this.otp.length === 6) this.$emit('otpEntered');
        },
        handlePaste(event) {
            this.otp = event.clipboardData.getData('text');
            if (this.otp.match(/[a-zA-Z._^%$#!~@,-]+/gm)) {
                this.$refs[0].value = '';
                this.$refs[1].value = '';
                this.$refs[2].value = '';
                this.$refs[3].value = '';
                this.$refs[4].value = '';
                this.$refs[5].value = '';
                return;
            }
            if (this.otp.length !== 6) {
                return;
            }
            this.$refs[0].value = this.otp[0];
            this.$refs[1].value = this.otp[1];
            this.$refs[2].value = this.otp[2];
            this.$refs[3].value = this.otp[3];
            this.$refs[4].value = this.otp[4];
            this.$refs[5].value = this.otp[5];
            this.$refs[5].focus();
            this.$emit('input', this.otp);
            this.$emit('otpEntered');
        },
        setOTP() {
            this.otp =
                this.$refs[0].value +
                this.$refs[1].value +
                this.$refs[2].value +
                this.$refs[3].value +
                this.$refs[4].value +
                this.$refs[5].value;

            this.$emit('input', this.otp);
        }
    }
};
