//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import OTPInput from './OTPInput.vue';
import HelperText from '~/components/ReusableComponents/HelperText.vue';
import { arrowLeft, checkBoxUnticked, checkboxTicked } from '~/static/icons/index';

export default {
    name: 'VerifyOtp',
    components: { OTPInput, HelperText },
    props: {
        title: {
            type: String,
            default: ''
        },
        subText: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        ctaText: {
            type: String,
            default: 'Verify and Proceed'
        },
        loadingCtaText: {
            type: String,
            default: 'Verifying...'
        }
    },

    data() {
        return {
            arrowLeft,
            chatBotAgree: true,
            otp: '',
            clearOtpInputs: false,
            checkBoxUnticked,
            checkboxTicked
        };
    },
    computed: {
        ...mapState('auth', {
            otpLoading: 'otpLoading',
            chatBotConsentStatus: 'chatBotConsentStatus',
            loginmethod: 'loginMethod'
        })
    },
    watch: {
        error(newVal) {
            if (newVal !== '') {
                this.$store.commit('auth/setOtpLoading', false);
            }
        }
    },
    methods: {
        updateConsent() {
            this.chatBotAgree = !this.chatBotAgree;
            this.$store.commit('auth/setChatBotAgreeStatus', this.chatBotAgree);
        },
        handleSubmit() {
            if (this.otpLoading || this.error) {
                return;
            }
            this.$emit('verify-otp', this.otp);
        },
        resendOTP() {
            this.otp = '';
            this.clearOtpInputs = true;
            this.$emit('resend-otp');
        },
        setStep1() {
            this.$emit('go-back');
            this.$store.commit('auth/setOtpLoading', false);
        }
    }
};
