var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col grow overflow-y-hidden overflow-x-hidden",class:[_vm.height, _vm.width]},[(_vm.showAuthLoader)?_c('Loader',{staticClass:"rounded-xl",attrs:{"type":"contained","marginClass":_vm.isAcquisitionAuth ? 'mt-[128px]' : '-mt-16',"background":"#fff"}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAuthLoader),expression:"!showAuthLoader"}],staticClass:"flex flex-col grow overflow-y-auto overflow-x-hidden relative",class:{ 'lg:pt-12': _vm.isModal, 'lg:pt-16': !_vm.isModal, 'pt-0': _vm.isAcquisitionAuth }},[(_vm.closable && !_vm.$device.isMobile)?_c('div',{staticClass:"p-4 absolute top-3 right-3 hover:bg-gray-50 rounded-full",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_c('img',{staticClass:"h-2.5 w-2.5 cursor-pointer",attrs:{"data-test":"close-button","src":_vm.cross}})]):_vm._e(),_vm._v(" "),(
                [
                    _vm.AUTH_STEP.PHONE_INPUT,
                    _vm.AUTH_STEP.EMAIL_INPUT,
                    _vm.AUTH_STEP.GOOGLE_SIGNIN
                ].includes(_vm.onboardingStep.stepName)
            )?_c('div',{staticClass:"h-full flex flex-col justify-between",class:[_vm.paddingX]},[_c('AuthMethodSelection',{attrs:{"login-signup-tabs":_vm.LOGIN_SIGNUP_TABS,"preselectedTab":_vm.selectedTab,"mainText":_vm.mainText,"subText":_vm.subText,"isModal":_vm.isModal,"isAcquisitionAuth":_vm.isAcquisitionAuth},on:{"selectTab":function($event){_vm.selectedTab = $event},"sendOtp":_vm.sendOTP,"handleGoogleAuth":_vm.handleGoogleAuth}}),_vm._v(" "),(_vm.isAcquisitionAuth)?_c('p',{staticClass:"text-xs text-center mt-3 mb-5 md:mb-10"},[_vm._v("\n                Already a member? Enter your\n                "+_vm._s(_vm.loginMethod === 'phone' ? 'number' : 'email')+" to login\n            ")]):_c('HelperText',{staticClass:"mb-4"})],1):_vm._e(),_vm._v(" "),(_vm.onboardingStep.stepName === _vm.AUTH_STEP.OTP_VERIFICATION)?_c('div',{staticClass:"h-full flex flex-col justify-between",class:[_vm.paddingX]},[_c('VerifyOtp',{class:{ 'sm:max-w-2xl lg:max-w-full': !_vm.$device.isMobile },attrs:{"title":_vm.verificationHeader,"sub-text":_vm.verificationSubText,"uuid":_vm.verifyUuid,"error":_vm.error,"ctaText":_vm.authFlow === _vm.AUTH_FLOW.INDIAN_GOOGLE
                        ? 'Verify and Create Account'
                        : 'Verify and Proceed',"loadingCtaText":"Verifying..."},on:{"resend-otp":function($event){return _vm.sendOTP({ isOtpResent: true })},"go-back":_vm.verifyOtpBackHandler,"verify-otp":_vm.verifyOTP,"resetError":_vm.resetError}})],1):_vm._e(),_vm._v(" "),(
                [_vm.AUTH_STEP.USER_AND_EXAM_DETAILS, _vm.AUTH_STEP.EXAM_DETAILS].includes(
                    _vm.onboardingStep.stepName
                )
            )?_c('SignupForm',{class:{ 'sm:max-w-2xl lg:max-w-full': !_vm.$device.isMobile },attrs:{"paddingX":_vm.paddingX,"webStepOneHeading":_vm.webStepOneHeading,"isModal":_vm.isModal,"ctaText":_vm.authFlow === _vm.AUTH_FLOW.INDIAN_GOOGLE ? 'Continue' : 'Create Account',"loadingCtaText":_vm.authFlow === _vm.AUTH_FLOW.INDIAN_GOOGLE ? 'Continue...' : 'Creating Account...'},on:{"saveDetailsAndRequestOtp":_vm.saveDetailsAndRequestOtp,"sign-up":_vm.signUp,"redirect-login":_vm.redirectLogin}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }