//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import PhoneAndEmailSignup from './PhoneAndEmailSignup.vue';
import SocialAuthSignup from './SocialAuthSignup.vue';
import { AUTH_FLOW } from '~/constants/authFlows.js';

export default {
    name: 'SignupForm',
    components: { PhoneAndEmailSignup, SocialAuthSignup },
    props: {
        webStepOneHeading: {
            type: String,
            default: 'Hello there! Let’s get introduced'
        },
        paddingX: {
            type: String,
            default: ''
        },
        isModal: {
            type: Boolean,
            default: false
        },
        ctaText: {
            type: String,
            default: 'Verify and Proceed'
        },
        loadingCtaText: {
            type: String,
            default: 'Verifying...'
        }
    },
    data() {
        return {
            // Constants
            AUTH_FLOW
        };
    },
    computed: {
        ...mapState('auth', {
            authFlow: 'authFlow'
        })
    },
    methods: {
        clearError(errorType) {
            this.$store.commit('auth/setRegisterLoading', false);
            if (errorType === 'firstName') {
                this.$store.commit('auth/setErrorFirstNameMessage', '');
            }
            if (errorType === 'lastName') {
                this.$store.commit('auth/setErrorLastNameMessage', '');
            }
            if (errorType === 'email') {
                this.$store.commit('auth/setErrorEmailMessage', '');
            }
            if (errorType === 'phone') {
                this.$store.commit('auth/setErrorPhoneMessage', '');
            }
        }
    }
};
