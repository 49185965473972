//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import AuthMethodSelection from './AuthMethodSelection.vue';
import VerifyOtp from './VerifyOtp.vue';
import SignupForm from './SignupForm.vue';
import HelperText from '~/components/ReusableComponents/HelperText.vue';
import Loader from '~/components/ReusableComponents/Loader.vue';
import { getAcquisitionDetails, isFeatureAllowed, toast } from '~/services/utilityFunctions';
import { trackLogin } from '~/services/analyticsTracker';
import { cross } from '~/static/icons/index';
import analyticEvents, { ANALYTIC_SOURCE_MODULES, AUTH_FLOW_TYPE } from '~/services/analyticEvents';
import { LOGIN_METHOD, AUTH_STEP, AUTH_FLOW } from '~/constants/authFlows.js';
import { ROUTES } from '~/constants/pageRoutes';
import { EXAM_TYPES, getTentativeTimeFromMonthObject } from '~/constants/onboardingFields';
import { PLATFORM_FEATURES } from '~/static/constants';

const LOGIN_SIGNUP_TABS = [
    { id: 1, value: 'login', title: 'Login' },
    { id: 2, value: 'signup', title: 'Sign up' }
];

export default {
    name: 'AuthContainer',
    components: {
        AuthMethodSelection,
        VerifyOtp,
        SignupForm,
        HelperText,
        Loader
    },
    inject: ['reCaptchaValidate'],
    props: {
        paddingX: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: 'w-full'
        },
        height: {
            type: String,
            default: 'h-full'
        },
        acquisitionAuthHeading: {
            type: String,
            default: 'Read all you need! No interruptions!'
        },
        acquisitionAuthSubheading: {
            type: String,
            default: 'Just sign up once!'
        },
        webStepZeroSignupHeading: {
            type: String,
            default: 'Start your Test Prep Journey Today!'
        },
        webStepZeroSignupSubHeading: {
            type: String,
            default: 'Sign up to start a smart and smooth test-taking experience.'
        },
        webStepZeroLoginHeading: {
            type: String,
            default: 'We’re glad you’re back!'
        },
        webStepZeroLoginSubHeading: {
            type: String,
            default: 'Login and continue working on getting the scores you want!'
        },
        webStepZeroLoginSubHeadingPwa: {
            type: String,
            default: 'Login and continue working on getting the scores you want!'
        },
        webStepOneHeading: {
            type: String,
            default: 'Hello there! Let’s get introduced'
        },
        wrongOtpValidationText: {
            type: String,
            default: 'Oops! This is not the code we sent you. Please try again.'
        },
        closable: {
            type: Boolean,
            default: false
        },
        defaultAuthType: {
            type: String,
            default: 'login'
        },
        isModal: {
            type: Boolean,
            default: false
        },
        signupType: {
            type: String,
            default: analyticEvents.signup_type.navbar // default signupType is `navbar_signup`
        },
        isDiagnosticTestAuth: {
            type: Boolean,
            default: false
        },
        isAcquisitionAuth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // ICONS
            cross,
            yocketPrepLogo: `${process.env.CDN_ENDPOINT}/testprep/static/yocketPrep.png`,

            // CONSTANTS
            LOGIN_SIGNUP_TABS,
            AUTH_STEP,
            AUTH_FLOW,
            ROUTES,

            // DATA
            selectedTab: LOGIN_SIGNUP_TABS[0],
            error: '',
            showAuthLoader: false
        };
    },
    computed: {
        ...mapState('auth', {
            authFlow: 'authFlow',
            user: 'user',
            onboardingStep: 'onboardingStep',
            continueRoute: 'continueRoute',
            loginMethod: 'loginMethod',
            phone: 'candidatePhone',

            email: 'candidateEmail',
            countryCode: 'candidateCountryCode',
            otpLoading: 'otpLoading',
            candidateFirstName: 'candidateFirstName',
            candidateLastName: 'candidateLastName',
            candidateEmail: 'candidateEmail',
            candidatePhone: 'candidatePhone',
            selectedCountryCode: 'selectedCountryCode',
            candidateExamType: 'candidateExamType',
            candidateWorkStatus: 'candidateWorkStatus',
            candidateExamAttemptStage: 'candidateExamAttemptStage',
            candidateExamRegistrationStatus: 'candidateExamRegistrationStatus',
            candidateExamDate: 'candidateExamDate',

            googleLoginCredential: 'googleLoginCredential',
            verifyUuid: 'verifyUuid',
            isDashboardSetup: 'isDashboardSetup',
            recentQuickDiagId: 'recentQuickDiagId',
            chatBotAgreeStatus: 'chatBotAgreeStatus',
            userCountry: 'userCountry',
            featurePermissions: 'featurePermissions',
            signupCtaSource: 'signupCtaSource'
        }),
        ...mapGetters({
            vbDashboardId: 'vocabBuilder/dashboardId',
            signupHook: 'auth/signupHook',
            showUpsellingModalOnRedirection: 'premium/showUpsellingModalOnRedirection'
        }),
        mainText() {
            if (this.isAcquisitionAuth) {
                return this.acquisitionAuthHeading;
            }

            if (this.selectedTab.value === 'login') {
                return this.webStepZeroLoginHeading;
            }
            return this.webStepZeroSignupHeading;
        },
        subText() {
            if (this.isAcquisitionAuth) {
                return this.acquisitionAuthSubheading;
            }

            if (this.selectedTab.value === 'login') {
                return this.$device.isMobile
                    ? this.webStepZeroLoginSubHeadingPwa
                    : this.webStepZeroLoginSubHeading;
            }
            return this.webStepZeroSignupSubHeading;
        },
        verificationHeader() {
            return this.loginMethod === LOGIN_METHOD.PHONE
                ? 'Mobile Verification'
                : 'Email Verification';
        },
        verificationSubText() {
            return this.loginMethod === LOGIN_METHOD.PHONE
                ? `We've sent a code to +${this.countryCode}-${this.phone}`
                : `We've sent a code to ${this.candidateEmail}`;
        }
    },
    created() {
        // if redirected after quick diagnostic test set the active selectedTab as signup
        if (
            this.defaultAuthType === 'signup' ||
            this.$route.query?.tab === 'signup' ||
            this.isDiagnosticTestAuth
        ) {
            this.selectedTab = this.LOGIN_SIGNUP_TABS.find((tab) => tab.value === 'signup');
        }

        // If it's not acquisition auth and the onboarding step is not at exam details(If an new user is coming from tapping the one-tap google auth popup), then always reset the auth flow to step 0

        if (
            !this.$route.query?.acq &&
            ![AUTH_STEP.EXAM_DETAILS, AUTH_STEP.USER_AND_EXAM_DETAILS].includes(
                this.onboardingStep?.stepName
            )
        ) {
            this.$store.commit('auth/resetOnboardingStep');
            this.$store.commit('auth/resetOnboardingDetails');
        }
    },
    destroyed() {
        this.$store.commit('auth/setSignupHook', '');
    },
    methods: {
        // -------------------------------------- Google Auth --------------------------------------
        async handleGoogleAuth(response) {
            // 1. Set the relevant auth flow (INDIAN_GOOGLE or INTERNATIONAL_GOOGLE)
            this.$store.commit('auth/setAuthFlow', {
                isInternationalUser: this.userCountry !== 'IN',
                loginMethod: LOGIN_METHOD.SOCIAL
            });

            // 2. Save the jwt credential in store
            const jwt = response.credential;
            this.$store.commit('auth/setGoogleLoginCredential', jwt);

            try {
                this.showAuthLoader = true;

                const gaClientId = await new Promise((resolve) => {
                    try {
                        window.gtag('get', process.env.GA_TRACKING_ID, 'client_id', (clientId) =>
                            resolve(clientId)
                        );
                        // if above callback (4th param) not called for 200 ms, resolve the promise
                        setTimeout(function () {
                            resolve(undefined);
                        }, 200);
                    } catch (error) {
                        resolve(undefined);
                    }
                });

                // 3. Construct api payload body
                // Mandatory fields
                const body = {
                    signin_method: 'google',
                    credentials: jwt,
                    device_source: 'w',
                    ga_client_id: gaClientId,
                    chatbot_consent: false
                };

                // Optional fields for international user
                if (this.authFlow !== AUTH_FLOW.INDIAN_GOOGLE) {
                    if (this.isDiagnosticTestAuth) {
                        // To link diagnostic test with new user on signup
                        body.test_taker_id = this.$route.query?.ttid;
                    }

                    // To link vocab connect dashboard with new user on signup
                    if (this.signupType === analyticEvents.signup_type.vocab_connect) {
                        body.vb_dashboard_id = this.vbDashboardId;
                    }
                }
                if (this.userCountry !== 'IN') {
                    const acquisitionDetails = getAcquisitionDetails(this.$route);
                    acquisitionDetails.source_cta = this.signupCtaSource ?? '';
                    body.acquisition_details = acquisitionDetails;
                }

                const {
                    data: { data, state }
                } =
                    this.userCountry === 'IN'
                        ? await this.$api.auth.indianSocialSignIn(body)
                        : await this.$api.auth.internationalSocialSignin(body);

                if (!state) {
                    const toastPayload = {
                        message: 'Something went wrong. Please try again later.'
                    };
                    toast.failureToast(toastPayload);
                    return;
                }

                let sourceModule = '';
                if (this.isAcquisitionAuth) {
                    sourceModule = ANALYTIC_SOURCE_MODULES.REDIRECTION_MODAL;
                } else if (this.signupType === analyticEvents.signup_type.navbar) {
                    sourceModule = ANALYTIC_SOURCE_MODULES.LOGIN_PAGE;
                } else {
                    sourceModule = ANALYTIC_SOURCE_MODULES.PRICING_PAGE_MODAL;
                }
                this.$store.dispatch('analytics/trackEvent', {
                    event: analyticEvents.google_auth_signup,
                    data: {
                        source_cta: 'gauth_btn',
                        is_new_user: data.is_new_user,
                        source: sourceModule
                    }
                });

                // Set the returned user object in store
                this.$store.commit('auth/setUser', data.user);
                this.$store.commit('auth/setCandidateFirstName', data.user?.first_name || '');
                this.$store.commit('auth/setCandidateLastName', data.user?.last_name || '');

                // If no basic user details are filled (fresh signup) then take to signup page
                if (data.is_new_user) {
                    // In case of pwa acquisition auth, only the google signin button is in modal
                    // Signup form is in full page view.
                    // Query parameter 'acq' will help in identifying this redirection
                    if (this.isAcquisitionAuth && this.$device.isMobileOrTablet) {
                        this.$router.push({
                            path: ROUTES.LOGIN,
                            query: { acq: true }
                        });
                    }

                    this.$store.commit('auth/setNextOnboardingStep');
                }
                // Else login and make the user fill necessary details in popup modal
                else {
                    await this.$store.dispatch('auth/getLoggedInUser');
                    this.$emit('userAuthenticated');

                    // confirm GA event to fire

                    const trackingEvent = {
                        event: analyticEvents.login_success
                    };
                    this.$store.dispatch('analytics/trackEvent', trackingEvent);

                    this.onComplete();
                    // DO NOT DELETE
                    trackLogin(this.user); // Track user UUID.
                }

                // GA event
                const trackingEvent = {
                    event: analyticEvents.google_signin_success
                };
                this.$store.dispatch('analytics/trackEvent', trackingEvent);

                // signup_success when new google signup
                if (data.is_new_user && this.authFlow === AUTH_FLOW.INTERNATIONAL_GOOGLE) {
                    const trackingEvent = {
                        event: analyticEvents.signup_success
                    };
                    this.$store.dispatch('analytics/trackEvent', trackingEvent);
                }
            } catch (error) {
                let errorMessage = 'Something went wrong. Please try again later.';
                if (error.response.data.errors.message)
                    errorMessage = error.response.data.errors.message;
                const toastPayload = {
                    message: errorMessage
                };
                toast.failureToast(toastPayload);
            } finally {
                this.showAuthLoader = false;
            }
        },

        async saveDetailsAndRequestOtp() {
            try {
                this.$store.commit('auth/setRegisterLoading', true);

                const body = {
                    signin_method: 'google',
                    credentials: this.googleLoginCredential,
                    country_code: this.selectedCountryCode.calling_code,
                    primary_phone: this.phone,
                    first_name: this.candidateFirstName,
                    last_name: this.candidateLastName,
                    device_source: 'w',
                    test: this.candidateExamType.value.toLowerCase(), // “GRE”, “GMAT”
                    is_working_professional: this.candidateWorkStatus.isWorking,
                    is_registered: this.candidateExamRegistrationStatus.hasRegistered
                };
                if ([EXAM_TYPES.GRE].includes(this.candidateExamType.value)) {
                    body.is_retake = !this.candidateExamAttemptStage.isFirstAttempt;
                }
                if (this.candidateExamDate.type === 'actual') {
                    body.exam_date = this.candidateExamDate.value;
                } else if (this.candidateExamDate.type === 'tentative') {
                    body.tentative_exam_date = {
                        from: this.candidateExamDate.value.fromMonth,
                        to: this.candidateExamDate.value.toMonth
                    };
                }

                // Save user details
                const {
                    data: { state }
                } = await this.$api.auth.saveUserDetails(body);

                // Request otp for entered phone
                if (state) {
                    await this.sendOTP({
                        loginMethod: this.loginMethod,
                        phone: this.phone,
                        countryCode: this.selectedCountryCode.calling_code
                    });
                }
            } catch (error) {
                let errorMessage = 'Something went wrong. Please try again!';
                if (error.response.data.errors.message)
                    errorMessage = error.response.data.errors.message;
                const toastPayload = {
                    message: errorMessage
                };
                toast.failureToast(toastPayload);
            } finally {
                this.$store.commit('auth/setRegisterLoading', false);
            }
        },
        // -------------------------------------- Google Auth --------------------------------------

        // -------------------------------------- Phone/Email Auth --------------------------------------
        async sendOTP({ loginMethod, email, phone, countryCode, isOtpResent }) {
            // GA event
            if ([AUTH_FLOW.INDIAN_PHONE, AUTH_FLOW.INTERNATIONAL_EMAIL].includes(this.authFlow)) {
                let sourceModule = '';
                if (this.isAcquisitionAuth) {
                    sourceModule = ANALYTIC_SOURCE_MODULES.REDIRECTION_MODAL;
                } else if (this.signupType === analyticEvents.signup_type.navbar) {
                    sourceModule = ANALYTIC_SOURCE_MODULES.LOGIN_PAGE;
                } else {
                    sourceModule = ANALYTIC_SOURCE_MODULES.PRICING_PAGE_MODAL;
                }

                this.$store.dispatch('analytics/trackEvent', {
                    event: analyticEvents.legacy_otp_sent,
                    data: {
                        source: sourceModule
                    }
                });
            }

            const eventName =
                this.selectedTab.value === 'login'
                    ? analyticEvents.login_otp_requested
                    : analyticEvents.signup_otp_requested;

            const trackingEvent = {
                event: eventName
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);

            this.error = '';
            this.$store.commit('auth/setCandidateCountryCode', countryCode || this.countryCode);
            this.$store.commit('auth/setCandidatePhone', phone || this.phone);
            this.$store.commit('auth/setLoginMethod', loginMethod || this.loginMethod);
            this.$store.commit('auth/setCandidateEmail', email || this.email);

            try {
                const captchaAction = `login_with_${this.loginMethod}`;
                const token = await this.reCaptchaValidate(captchaAction);
                const body = {
                    login_method: this.loginMethod,
                    otp_method: 'text',
                    device_source: 'w',
                    captcha_token: token,
                    captcha_action: captchaAction
                };

                if (this.loginMethod === LOGIN_METHOD.PHONE) {
                    body.phone = this.phone;
                    body.country_code = this.countryCode;
                } else {
                    body.email = this.email;
                }

                const {
                    data: { data, state }
                } = await this.$api.auth.sendOtp(body);

                if (state) {
                    this.$store.commit('auth/setVerifyUuid', data.verify_uuid);
                    this.$store.commit('auth/setChatBotConsentStatus', data.chatbot_consent_given);

                    if (!isOtpResent) {
                        this.$store.commit('auth/setNextOnboardingStep');
                    }
                    this.$store.commit('auth/setOtpLoading', false);

                    // In case of pwa acquisition auth, only the phone number/email input is in modal
                    // Verify otp onwards it is in full page view.
                    // Query parameter 'acq' will help in identifying this redirection
                    if (this.isAcquisitionAuth && this.$device.isMobileOrTablet) {
                        this.$router.push({
                            path: ROUTES.LOGIN,
                            query: { acq: true }
                        });
                    }
                }
            } catch (error) {
                const errorMessage = error.response.data.errors.message;
                const toastPayload = {
                    message: errorMessage
                };
                toast.failureToast(toastPayload);
                this.$store.commit('auth/setOtpLoading', false);
            }
        },
        async verifyOTP(otp) {
            // GA event
            const eventName =
                this.selectedTab.value === 'login'
                    ? analyticEvents.login_otp_verified
                    : analyticEvents.signup_otp_verified;

            const trackingEvent = {
                event: eventName
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);

            try {
                this.$store.commit('auth/setOtpLoading', true);
                const gaClientId = await new Promise((resolve) => {
                    try {
                        window.gtag('get', process.env.GA_TRACKING_ID, 'client_id', (clientId) =>
                            resolve(clientId)
                        );
                        // if above callback (4th param) not called for 200 ms, resolve the promise
                        setTimeout(function () {
                            resolve(undefined);
                        }, 200);
                    } catch (error) {
                        resolve(undefined);
                    }
                });
                const acquisitionDetails = getAcquisitionDetails(this.$route);
                acquisitionDetails.source_cta = this.signupCtaSource ?? '';
                const body = {
                    login_method: this.loginMethod,
                    otp,
                    verify_uuid: this.verifyUuid,
                    device_source: 'w',
                    ga_client_id: gaClientId,
                    chatbot_consent: this.chatBotAgreeStatus,
                    acquisition_details: acquisitionDetails
                };

                // To link diagnostic test with new user
                if (this.isDiagnosticTestAuth) {
                    body.test_taker_id = this.$route.query?.ttid;
                }

                // To link vocab connect dashboard with new user
                if (this.signupType === analyticEvents.signup_type.vocab_connect) {
                    body.vb_dashboard_id = this.vbDashboardId;
                }

                const {
                    data: { state, data }
                } = await this.$api.auth.verifyOtp(body);

                if (!state) {
                    const toastPayload = {
                        message: 'Something went wrong. Please try again later.'
                    };
                    toast.failureToast(toastPayload);
                    return;
                }

                // If otp is verified from legacy auth flow
                if (
                    [AUTH_FLOW.INDIAN_PHONE, AUTH_FLOW.INTERNATIONAL_EMAIL].includes(this.authFlow)
                ) {
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.legacy_otp_verified,
                        data: {
                            is_new_user: data.is_new_user
                        }
                    });
                }

                // If otp is verified from google auth flow and the user is new
                if (
                    [AUTH_FLOW.INDIAN_GOOGLE, AUTH_FLOW.INTERNATIONAL_GOOGLE].includes(
                        this.authFlow
                    ) &&
                    data.is_new_user
                ) {
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.gauth_verify_otp
                    });
                }
                // If it is an Indian Google auth flow, call the signup API and create account.
                if (this.authFlow === AUTH_FLOW.INDIAN_GOOGLE) {
                    // signup_success event when otp is verified
                    if (data.is_new_user) {
                        const trackingEvent = {
                            event: analyticEvents.signup_success
                        };
                        this.$store.dispatch('analytics/trackEvent', trackingEvent);
                    }

                    // call signup api
                    await this.signUp();
                    return;
                }

                // Else
                // If no basic user details are filled (fresh signup) then take to signup page
                // else login and make the user fill necessary details in popup modal
                if (data.profile_completion_level === 0) {
                    this.$store.commit('auth/setNextOnboardingStep');

                    // signup_success when otp verified during phone/email signup only for the very first time
                    if (data.is_new_user) {
                        const trackingEvent = {
                            event: analyticEvents.signup_success
                        };
                        this.$store.dispatch('analytics/trackEvent', trackingEvent);
                    }
                } else {
                    await this.$store.dispatch('auth/getLoggedInUser');
                    this.$emit('userAuthenticated');

                    const trackingEvent = {
                        event: analyticEvents.login_success
                    };
                    this.$store.dispatch('analytics/trackEvent', trackingEvent);

                    // DO NOT DELETE
                    trackLogin(this.user);
                    this.onComplete();
                }
            } catch (error) {
                this.error = this.wrongOtpValidationText;
            } finally {
                this.$store.commit('auth/setOtpLoading', false);
            }
        },
        // -------------------------------------- Phone/Email Auth --------------------------------------

        // Signup method is common for all
        async signUp() {
            try {
                // GRE exam details
                const body = {
                    test: this.candidateExamType.value.toLowerCase(), // “GRE”,”GMAT”
                    is_working_professional: this.candidateWorkStatus.isWorking,
                    is_registered: this.candidateExamRegistrationStatus.hasRegistered
                };
                // candidateExamType.value accepts "GRE" and "GMAT"
                if ([EXAM_TYPES.GRE].includes(this.candidateExamType.value)) {
                    body.is_retake = !this.candidateExamAttemptStage.isFirstAttempt;
                }
                if (this.candidateExamDate.type === 'actual') {
                    body.exam_date = this.candidateExamDate.value;
                } else if (this.candidateExamDate.type === 'tentative') {
                    body.tentative_exam_date = {
                        from: this.candidateExamDate.value.fromMonth,
                        to: this.candidateExamDate.value.toMonth
                    };
                }

                // User's basic details
                if (this.authFlow === AUTH_FLOW.INDIAN_GOOGLE) {
                    body.login_method = LOGIN_METHOD.PHONE;
                    body.verify_uuid = this.verifyUuid;
                    body.first_name = this.candidateFirstName;
                    body.last_name = this.candidateLastName;
                    body.device_source = 'w';
                    body.primary_email = this.user.primary_email.toLowerCase();
                } else if (this.authFlow === AUTH_FLOW.INTERNATIONAL_GOOGLE) {
                    if (this.candidatePhone) {
                        body.country_code = this.selectedCountryCode.calling_code;
                        body.primary_phone = this.candidatePhone;
                    }
                } else {
                    body.login_method = this.loginMethod;
                    body.verify_uuid = this.verifyUuid;
                    body.first_name = this.candidateFirstName;
                    body.last_name = this.candidateLastName;
                    body.device_source = 'w';

                    if (this.loginMethod === LOGIN_METHOD.PHONE) {
                        body.primary_email = this.candidateEmail.toLowerCase();
                    }

                    // pass phone number as well (optional)
                    if (this.loginMethod === LOGIN_METHOD.EMAIL && this.candidatePhone) {
                        body.country_code = this.selectedCountryCode.calling_code;
                        body.primary_phone = this.candidatePhone;
                    }
                }

                // To link free starter kit
                if (this.$route.query?.src === 'free-starter-kit') {
                    body.signup_url = 'free-starter-kit';
                }

                let response = null;
                if (this.authFlow === AUTH_FLOW.INTERNATIONAL_GOOGLE) {
                    response = await this.$api.users.updateProfile(body);
                } else {
                    response = await this.$api.auth.signup(body);
                }

                const {
                    data: { state }
                } = response;

                // set the user and signup status after creating account and redirect to home
                if (state) {
                    // Signup completed event for all users at this point
                    const flow = [AUTH_FLOW.INTERNATIONAL_GOOGLE, AUTH_FLOW.INDIAN_GOOGLE].includes(
                        this.authFlow
                    )
                        ? AUTH_FLOW_TYPE.GAUTH
                        : AUTH_FLOW_TYPE.LEGACY;

                    const registration = this.candidateExamRegistrationStatus.hasRegistered
                        ? 'Yes'
                        : 'No';
                    const trackingEventData = {
                        flow,
                        first_name: this.candidateFirstName,
                        last_name: this.candidateLastName,
                        email: this.candidateEmail || undefined,
                        mobile: this.candidatePhone || undefined,
                        exam_type: this.candidateExamType.value,
                        role: this.candidateWorkStatus?.label,
                        attempt_stage: this.candidateExamAttemptStage?.label,
                        registration,
                        account_created: new Date().toUTCString()
                    };

                    if (
                        this.candidateExamDate.value?.fromMonth !== undefined &&
                        this.candidateExamDate.value?.toMonth !== undefined
                    ) {
                        trackingEventData.tentative_date = getTentativeTimeFromMonthObject({
                            fromMonth: this.candidateExamDate.value?.fromMonth,
                            toMonth: this.candidateExamDate.value?.toMonth
                        });
                    } else {
                        trackingEventData.exam_date = this.candidateExamDate.value;
                    }

                    let trackingEvent = {
                        event: analyticEvents.create_account,
                        data: trackingEventData
                    };

                    this.$store.dispatch('analytics/trackEvent', trackingEvent);

                    trackingEvent = {
                        event: analyticEvents.signup_completed,
                        data: {
                            signup_type: this.signupType
                        }
                    };
                    this.$store.dispatch('analytics/trackEvent', trackingEvent);

                    // If it is acquisition auth signup, override the default event
                    if (this.isAcquisitionAuth || this.$route.query?.acq) {
                        trackingEvent = {
                            event: analyticEvents.signup_acqmodal_success
                        };
                        this.$store.dispatch('analytics/trackEvent', trackingEvent);
                    }
                    // candidateExamType.value accepts "GRE" and "GMAT"
                    if ([EXAM_TYPES.GRE].includes(this.candidateExamType.value)) {
                        // Track Trial Begin
                        trackingEvent = {
                            event: analyticEvents.trial_started
                        };
                        this.$store.dispatch('analytics/trackEvent', trackingEvent);
                    }
                    // Signup through lead magnet page
                    if (this.$route.query?.src === 'free-starter-kit') {
                        trackingEvent = {
                            event: analyticEvents.signup_magnet_success
                        };
                        this.$store.dispatch('analytics/trackEvent', trackingEvent);
                    }

                    await this.$store.dispatch('auth/getLoggedInUser');
                    this.$emit('userAuthenticated');
                    this.onComplete();

                    const toastPayload = {
                        message: 'Yay! You’ve successfully created your account'
                    };

                    toast.successToast(toastPayload);
                    this.$store.commit('auth/resetOnboardingDetails');
                    // DO NOT DELETE
                    trackLogin(this.user); // get it from store.
                }
            } catch (error) {
                let errorMessage = 'Something went wrong. Please try again later.';
                if (error.response.data.errors.message)
                    errorMessage = error.response.data.errors.message;
                const toastPayload = {
                    message: errorMessage
                };
                toast.failureToast(toastPayload);
            } finally {
                this.$store.commit('auth/setRegisterLoading', false);
            }
        },

        // --------------------------------- UI handling ---------------------------------
        fireGAEvent() {
            const fromRoute = this.$router.history._startLocation;
            const eventName =
                this.selectedTab.value === 'login'
                    ? analyticEvents.login_click
                    : analyticEvents.signup_click;

            const trackingEvent = {
                event: eventName,
                data: {
                    source: 'auth_screen',
                    referred_from: fromRoute.includes('questions')
                        ? 'quick_diag_finish'
                        : 'navbar_click'
                }
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        },
        tabChanged() {
            if (this.selectedTab.value === 'signup' && this.userCountry !== 'IN')
                this.$store.commit('auth/setLoginMethod', 'email');

            this.fireGAEvent();
        },
        redirectLogin({ phoneNumberVisible, emailVisible }) {
            if (!phoneNumberVisible) this.$store.commit('auth/setCandidatePhone', '');
            if (!emailVisible) this.$store.commit('auth/setCandidateEmail', '');

            this.selectedTab.value = 'login';
            // this.$store.commit('auth/setPreviousOnboardingStep', 0);

            // clear any prefilled details from last round of signup
            this.$store.commit('auth/resetOnboardingDetails');
        },
        resetError() {
            this.error = '';
        },
        onComplete() {
            let continueRoute = ROUTES.HOME;
            if (this.isDiagnosticTestAuth && !this.isDashboardSetup) {
                continueRoute = `${ROUTES.PERFORMANCE}?ttid=${this.recentQuickDiagId}`;
            }
            if (!isFeatureAllowed(PLATFORM_FEATURES.PRICING, this.featurePermissions)) {
                this.$router.push(continueRoute);
                return;
            }
            if (this.$route.query?.src === 'free-starter-kit') {
                continueRoute = ROUTES.FREEBIES;
            }

            // Reset plans object and then fetch according to logged in user
            this.$store.commit('premium/setAllPlans', null);

            if (this.continueRoute) {
                continueRoute = this.continueRoute;
                this.$store.commit('auth/setContinueRoute', ROUTES.HOME);
            }

            this.$store.commit('auth/clearAcquisitionAuthTimer', { hideModal: true });
            // In acquisition auth PWA, the login starts from modal and ends on full page auth,
            // therefore, to determine and handle acquisition auth, a query param is passed
            if (this.$device.isMobileOrTablet && this.$route.query?.acq) {
                this.$store.commit('auth/toggleTimerBasedAcqAuthModal', false);
                this.$router.go(-1);
                return;
            }

            if (this.$device.isMobileOrTablet && this.showUpsellingModalOnRedirection) {
                this.$router.go(-1);
                return;
            }
            // If it is an auth modal then don't redirect anywhere
            if (!this.isModal) this.$router.push(continueRoute);
        },
        verifyOtpBackHandler() {
            if (this.$route.query?.acq) {
                this.$router.go(-1);
            }
            this.$store.commit('auth/setPreviousOnboardingStep');
        },
        routeToFreeStarter() {
            window.open(`${window.location.origin}/${ROUTES.FREE_STARTER_KIT}`, '_blank');
            this.$store.commit('auth/toggleTimerBasedAcqAuthModal', false);
        }
        // --------------------------------- UI handling ---------------------------------
    }
};
