//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'HorizontalTabsNav',
    props: {
        tabs: {
            type: Array,
            default: () => []
        },
        value: {
            default: {}
        },
        paddingX: {
            type: String,
            default: ''
        },
        matchingKey: {
            type: String,
            default: 'id'
        },
        displayKey: {
            type: String,
            default: 'title'
        },
        spacedEven: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeTab(tab, index) {
            if (tab.disabled) return;
            this.$emit('input', tab);
            this.$emit('selectIndex', index);
            this.$emit('change', tab);
        }
    }
};
