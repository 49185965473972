//
//
//
//
//
//
//
//
//

export default {
    name: 'OtpTimer',
    components: {},
    props: {
        seconds: {
            type: Number,
            default: 45
        },
        callback: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            timer: null,
            remainingSeconds: 30
        };
    },
    created() {
        if (this.seconds) {
            this.remainingSeconds = +this.seconds;
        }
        this.startTimer();
    },
    methods: {
        startTimer() {
            this.timer = setInterval(() => {
                if (this.remainingSeconds === 0) {
                    this.stopTimer();
                    return;
                }
                this.remainingSeconds = this.remainingSeconds - 1;
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
            this.timer = null;
            this.callback();
        }
    }
};
