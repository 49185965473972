//
//
//
//
//
//
//
//
//

import { OTHER_LINKS } from '~/static/constants.js';
export default {
    name: 'HelperText',
    data() {
        return { OTHER_LINKS };
    }
};
